import IMask from "imask";
import _ from "lodash";
import VanillaModal from "vanilla-modal";
import axios from "axios";

const webhookAdress =
  "https://hooks.slack.com/services/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";

const modal = new VanillaModal();

const phoneMask = new IMask(document.getElementById("contacts-form-tel"), {
  mask: "+{7} (000) 000-00-00",
});

var dynamicMask = new IMask(document.getElementById("contacts-form-mail"), {
  mask: /^\S*@?\S*$/,
});

const contactsform = document.getElementById("contacts-form-form");
const modalform = document.getElementById("modals-form-form");

const webhookHandler = (e, target) => {
  const form = e.target;
  const { name, email, phone, message } = _.fromPairs([...new FormData(form)]);
  const options = {
    text: `${name} оставил сообщение`,
    attachments: [
      {
        fallback: `Имя: ${name}, Email: ${email}, Телефон: ${phone}, Сообщение: ${message}`,
        text: `${message}`,
        fields: [
          {
            title: "Email",
            value: `${email}`,
            short: true,
          },
          {
            title: "Phone",
            value: `${phone}`,
            short: true,
          },
        ],
      },
    ],
  };
  axios
    .post(target, JSON.stringify(options))
    .then((response) => {
      console.info("SUCCEEDED: Sent slack webhook: \n", response.data);
      modal.open("#modal-2");
      form.reset();
    })
    .catch((error) => {
      console.error("FAILED: Send slack webhook", error);
      modal.open("#modal-3");
      reject(new Error("FAILED: Send slack webhook"));
    });
};

contactsform.onsubmit = (e) => {
  e.preventDefault();
  webhookHandler(e, webhookAdress);
};

modalform.onsubmit = (e) => {
  e.preventDefault();
  webhookHandler(e, webhookAdress);
};
