import IMask from "imask";

const phoneMask = new IMask(
  document.getElementById("modals-contacts-form-tel"),
  {
    mask: "+{7} (000) 000-00-00"
  }
);

var dynamicMask = new IMask(
  document.getElementById("modals-contacts-form-mail"),
  {
    mask: /^\S*@?\S*$/
  }
);
