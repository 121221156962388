const progressValue = document.querySelectorAll(".progress .progress__value");
const progressContainers = document.querySelectorAll(".progress-container");

const RADIUS = 70;
const RADIUSHIDDEN = 90;

const circumference = rad => 2 * Math.PI * rad;

const progress = (value, rad) => {
  const progress = value / 100;
  return circumference(rad) * (1 - progress);
};

[...progressValue].forEach(element => {
  const value = element.getAttribute("data-value"); //ie11
  element.style.strokeDasharray = circumference(RADIUS);
  element.style.strokeDashoffset = progress(value, RADIUS);
});

[...progressContainers].forEach(element => {
  element.addEventListener("mouseenter", event => {
    const circle = event.target.querySelector(".progress .progress__value");
    const circleHidden = event.target.querySelector(
      ".progress-hiden .progress__value"
    );
    const value = circle.getAttribute("data-value"); //e11
    element.style.strokeDasharray = circumference(RADIUSHIDDEN);
    circleHidden.style.strokeDashoffset = progress(value, RADIUSHIDDEN);
  });
  element.addEventListener("mouseleave", event => {
    const circle = event.target.querySelector(".progress .progress__value");
    const circleHidden = event.target.querySelector(
      ".progress-hiden .progress__value"
    );
    element.style.strokeDasharray = circumference(RADIUSHIDDEN);
    circleHidden.style.strokeDashoffset = circumference(RADIUSHIDDEN);
  });
});
