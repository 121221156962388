let scrollpos = window.scrollY;
const header = document.querySelector(".navbar-cont");
const header_height = header.offsetHeight;
const add_class_on_scroll = function () {
  header.classList.add("fade-in");
};
const remove_class_on_scroll = function () {
  header.classList.remove("fade-in");
};
window.addEventListener("scroll", function () {
  scrollpos = window.scrollY;
  if (scrollpos >= header_height) {
    add_class_on_scroll();
  } else {
    remove_class_on_scroll();
  }
});
